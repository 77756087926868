export const config = {
    APP_URL: "https://mpd-mobile-dev.pmcc4thwatch.org/",
    LOCALHOST: "http://localhost",
    SERVER_URL: "https://mpd-mobile-dev.pmcc4thwatch.org/",
    LOGIN_SERVICE_URL: "https://login-service.pmcc4thwatch.org",
    MEMBER_SERVICE_URL: "https://mpd-mobile-backend-dev.pmcc4thwatch.org",
    // MEMBER_SERVICE_URL: "http://dev.pmccmembership.com",
    GC_SERVICE_URL: {
        url: "https://gc-service.pmcc4thwatch.org",
        token: "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJwb3BveUB5aG13aHkuY29tIiwiZXhwIjoxOTE0MDQwMjQxLCJpYXQiOjE1OTg1MDc0NDF9.zMgn_uqT0H_gg_j-Fn_xUHtEBFgC1eAuojpRNuWDo4M",
    },
    GLOBAL_CONFIG_SERVICE_URL: "https://gc-service.pmcc4thwatch.org",
    MPD_SERVICE_URL: "https://mpd2-dev.pmcc4thwatch.org",
};
