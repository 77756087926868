import { React, Component, useState, useEffect} from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDrag } from '@use-gesture/react';

import { api } from '../api.js';
import { config } from '../config.js';

import moment from 'moment';
import Slider from "react-slick";

import pmccIcon from "../assets/pmcc-icon.png";
import fillerPhoto from "../assets/filler.png";

import bibleStudentsIco from "../assets/dashboard_icons/bible-students.png";
import bishopsIco from "../assets/dashboard_icons/bishops.png";
import churchesIco from "../assets/dashboard_icons/churches.png";
import districtsIco from "../assets/dashboard_icons/districts.png";
import headPastorsIco from "../assets/dashboard_icons/head-pastors.png";
import ministersIco from "../assets/dashboard_icons/ministers.png";
import presbytersIco from "../assets/dashboard_icons/presbyters.png";

import CommonLoader from "../CommonComponents/CommonLoader";
import AuthenticatedHeader from "../Components/AuthenticatedHeader";
import HeaderSearch from "../Components/HeaderSearch";

const token = localStorage.getItem("auth_token");
const headerReq = { token };
const Dashboard = () => {

    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [churchesCount, setChurchesCount] = useState(0);
    const [districtCount, setDistrictCount] = useState(0);
    const [countryCount, setCountryCount] = useState(0);

    const [bishopCount, setBishopCount] = useState(0);
    const [presbyterCount, setPresbyterCount] = useState(0);
    const [coordinatorCount, setCoordinatorCount] = useState(0);
    const [headPastorCount, setHeadPastorCount] = useState(0);
    const [ministerCount, setMinisterCount] = useState(0);
    const [bibleStudentCount, setBibleStudentCount] = useState(0);
    const [ampCount, setAmpCount] = useState(0);
    const [deceasedCount, setDeceasedCount] = useState(0);

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    // useEffect hook to perform side effect (API call)
    useEffect(() => {

        // Call the async function
        fetchLocaleChurches();
        dashboardStats();
        membersPerLocale();

    }, []); // Empty dependency array means this effect runs once after mount

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */
    
    const fetchLocaleChurches = async () => {
        try {    
            api("gc_all_locales", headerReq, null, "member")
            .then((result) => {

                localStorage.setItem("locale_churches", JSON.stringify(result));


                /* ####################### DISTRICT PROCESSING ####################### */
                
                // Get all unique area_id values
                const uniqueAreaIds = new Set(result.map(item => item.area_id));

                // Count unique area_id values
                const uniqueAreaCount = uniqueAreaIds.size;

                /* ####################### DISTRICT PROCESSING ####################### */


                /* ####################### COUNTRY PROCESSING ####################### */
                
                // Get all unique area_id values
                const uniqueCountryIds = new Set(result.map(item => item.country_id));

                // Count unique area_id values
                const uniqueCountryCount = uniqueCountryIds.size;

                /* ####################### COUNTRY PROCESSING ####################### */

                setCountryCount(uniqueCountryCount);
                setDistrictCount(uniqueAreaCount);
                setChurchesCount(result.length);

            }).catch((error) => {

                console.log(error);
                console.log("Something went wrong. " + JSON.stringify(error));

            });
        } catch (err) {
            // Handle any errors that occur during the API call
            setError(err);
        } finally {
            // Update loading state regardless of success or error
            setLoading(false);
        }

    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const dashboardStats = async () => {
        try {    
            api("dashboardStats", headerReq, null, "member")
            .then((result) => {

                let resultBishopCount = 0;
                let resultPresbyterCount = 0;
                let resultCoordinatorCount = 0;
                let resultHeadPastorCount = 0;
                let resultMinisterCount = 0;
                let resultBibleStudentCount = 0;

                let minister_types = [
                    "minister",
                    "bishop",
                    "coordinator",
                    "council",
                    "presbyter"
                ];

                for(let i=0; i < result.members_by_type.length; i++) {
                    
                    if(result.members_by_type[i].type.toLowerCase().includes("bishop")) {
                        resultBishopCount += result.members_by_type[i].count;
                    }

                    if(result.members_by_type[i].type.toLowerCase().includes("presbyter")) {
                        resultPresbyterCount += result.members_by_type[i].count;
                    }

                    if(result.members_by_type[i].type.toLowerCase().includes("coordinator")) {
                        resultCoordinatorCount += result.members_by_type[i].count;
                    }

                    if(result.members_by_type[i].type.toLowerCase().includes("head minister") && !result.members_by_type[i].type.toLowerCase().includes("assistant")) {
                        resultHeadPastorCount += result.members_by_type[i].count;
                    }

                    if(result.members_by_type[i].type.toLowerCase().includes("bible student")) {
                        resultBibleStudentCount += result.members_by_type[i].count;
                    }

                    if (minister_types.some(type => result.members_by_type[i].type.toLowerCase().includes(type))) {
                        resultMinisterCount += result.members_by_type[i].count;
                    }
                }

                setBishopCount(resultBishopCount);
                setPresbyterCount(resultPresbyterCount);
                setCoordinatorCount(resultCoordinatorCount);
                setHeadPastorCount(resultHeadPastorCount);
                setMinisterCount(resultMinisterCount);
                setBibleStudentCount(resultBibleStudentCount);
                setAmpCount(result.amp_count);
                setDeceasedCount(result.deceased_count);

            }).catch((error) => {

                console.log(error);
                console.log("Something went wrong. " + JSON.stringify(error));

            });
        } catch (err) {
            // Handle any errors that occur during the API call
            setError(err);
        } finally {
            // Update loading state regardless of success or error
            setLoading(false);
        }
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */
    
    const membersPerLocale = async () => {
        try {    
            api("membersPerLocale", headerReq, null, "member")
            .then((result) => {

                localStorage.setItem("locale_brethren_counts", JSON.stringify(result));

            }).catch((error) => {

                console.log(error);
                console.log("Something went wrong. " + JSON.stringify(error));

            });
        } catch (err) {
            // Handle any errors that occur during the API call
            setError(err);
        } finally {
            // Update loading state regardless of success or error
            setLoading(false);
        }
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    if(!loading) {
        let now = moment();
        let display_date = now.format('MMMM D, YYYY');
        return (
            <div id="main-dashboard">
                <AuthenticatedHeader />

                <div className="wrapper">
                    <div className="section-limiter">
                        <HeaderSearch />
                        {/*<form ref={this.goodmanIdForm} id="minister-search" method="POST" onSubmit={this.handleSubmit}>
                            <div id="searchWrapper">
                                <div className="form-group">
                                    <input   onChange={(event) => this.handleChange(event)} className="form-control" type="text" placeholder="Search" />
                                </div>
                            </div>
                        </form>*/}
                    </div>
                    <div className="section-limiter">
                        <div id="dashboard-header">
                            <center>
                                <h1>
                                    Church Dashboard<br />
                                    <small id="dashboard-date">as of {display_date}</small>
                                </h1>
                            </center>
                        </div>
                    </div>

                    <div id="dashboard-stats">
                        <div className="row">
                            <div className="col-6">
                                <a href="/districts">
                                    <div className="stat-instance">
                                        <div className="category-icon">
                                            <img src={districtsIco} />
                                        </div>
                                        <div className="category-details">
                                            <div className="category-title">Districts</div>
                                            <div className="category-count">{districtCount.toLocaleString()}</div>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-6">
                                <a href="/all-churches">
                                    <div className="stat-instance">
                                        <div className="category-icon">
                                            <img src={churchesIco} />
                                        </div>
                                        <div className="category-details">
                                            <div className="category-title">Churches</div>
                                            <div className="category-count">{churchesCount.toLocaleString()}</div>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-6">
                                <a href="/countries">
                                    <div className="stat-instance">
                                        <div className="category-icon">
                                            <img src={districtsIco} />
                                        </div>
                                        <div className="category-details">
                                            <div className="category-title">Countries</div>
                                            <div className="category-count">{countryCount.toLocaleString()}</div>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-6">
                                <a href="/member-type/bishop">
                                    <div className="stat-instance">
                                        <div className="category-icon">
                                            <img src={churchesIco} />
                                        </div>
                                        <div className="category-details">
                                            <div className="category-title">Bishops</div>
                                            <div className="category-count">{bishopCount.toLocaleString()}</div>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-6">
                                <a href="/member-type/presbyter">
                                    <div className="stat-instance">
                                        <div className="category-icon">
                                            <img src={districtsIco} />
                                        </div>
                                        <div className="category-details">
                                            <div className="category-title">Presbyters</div>
                                            <div className="category-count">{presbyterCount.toLocaleString()}</div>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-6">
                                <a href="/member-type/coordinator">
                                    <div className="stat-instance">
                                        <div className="category-icon">
                                            <img src={churchesIco} />
                                        </div>
                                        <div className="category-details">
                                            <div className="category-title">Coordinators</div>
                                            <div className="category-count">{coordinatorCount.toLocaleString()}</div>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-6">
                                <a href="/member-type/head pastor">
                                    <div className="stat-instance">
                                        <div className="category-icon">
                                            <img src={districtsIco} />
                                        </div>
                                        <div className="category-details">
                                            <div className="category-title">Head Pastors</div>
                                            <div className="category-count">{headPastorCount.toLocaleString()}</div>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-6">
                                <a href="/member-type/minister">
                                    <div className="stat-instance">
                                        <div className="category-icon">
                                            <img src={churchesIco} />
                                        </div>
                                        <div className="category-details">
                                            <div className="category-title">Ministers</div>
                                            <div className="category-count">{ministerCount.toLocaleString()}</div>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-6">
                                <a href="/member-type/bible student">
                                    <div className="stat-instance">
                                        <div className="category-icon">
                                            <img src={districtsIco} />
                                        </div>
                                        <div className="category-details">
                                            <div className="category-title">Bible Students</div>
                                            <div className="category-count">{bibleStudentCount.toLocaleString()}</div>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-6">
                                <a href="/member-type/amp">
                                    <div className="stat-instance">
                                        <div className="category-icon">
                                            <img src={churchesIco} />
                                        </div>
                                        <div className="category-details">
                                            <div className="category-title">Amp</div>
                                            <div className="category-count">{ampCount.toLocaleString()}</div>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-6">
                                <a href="/member-type/deceased">
                                    <div className="stat-instance">
                                        <div className="category-icon">
                                            <img src={districtsIco} />
                                        </div>
                                        <div className="category-details">
                                            <div className="category-title">Deceased</div>
                                            <div className="category-count">{deceasedCount.toLocaleString()}</div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
      return (
        <CommonLoader />
      );
    }
}

export default Dashboard;