import Dashboard from "../Pages/dashboard";
import Contact from "../Pages/contacts";
import Locale from "../Pages/locale";
import ContactProfile from "../Pages/contact-profile";
import MinisterList from "../Pages/minister-list";
import Countries from "../Pages/countries";
import Country from "../Pages/country";
import CountryPhilippines from "../Pages/country-philippines";
import StateProvince from "../Pages/state-province";
import AllChurches from "../Pages/all-churches";
import MemberByType from "../Pages/member-by-type";
import District from "../Pages/district";
import DistrictChurches from "../Pages/district-churches";

const routes = [
    {
        path: "/dashboard",
        element: <Dashboard />,
        exact: true,
    },

    {
        path: "/minister-list",
        element: <MinisterList />,
        exact: true,
    },

    {
        path: "/contacts",
        element: <Contact />,
        exact: true,
    },

    {
        path: "/contact-profile/:id",
        element: <ContactProfile />,
        exact: true,
    },

    {
        path: "/countries",
        element: <Countries />,
        exact: true,
    },

    {
        path: "/country-philippines",
        element: <CountryPhilippines />,
        exact: true,
    },

    {
        path: "/country/:country/:country_id",
        element: <Country />,
        exact: true,
    },

    {
        path: "/state/:state/:state_id",
        element: <StateProvince />,
        exact: true,
    },

    {
        path: "/locale/:locale_id",
        element: <Locale />,
        exact: true,
    },

    {
        path: "/all-churches",
        element: <AllChurches />,
        exact: true,
    },

    {
        path: "/districts",
        element: <District />,
        exact: true,
    },

    {
        path: "/member-type/:type",
        element: <MemberByType />,
        exact: true,
    },

    {
        path: "/district-churches/:district_name/:district_id",
        element: <DistrictChurches />,
        exact: true,
    }
];

export default routes;
