import { React, Component, useState, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDrag } from '@use-gesture/react';

import { api } from '../api.js';
import { config } from '../config.js';

import pmccIcon from "../assets/pmcc-icon.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AuthenticatedHeader from "../Components/AuthenticatedHeader";
import HeaderSearch from "../Components/HeaderSearch";
import CommonLoader from "../CommonComponents/CommonLoader";

import CountryPhilippines from "./country-philippines";

const localStorage_localChurches = JSON.parse(localStorage.getItem("locale_churches"));
const localStorage_localeBrethrenCounts = JSON.parse(localStorage.getItem("locale_brethren_counts"));
const token = localStorage.getItem("auth_token");
const headerReq = { token };
const Country = () => {

    const navigate = useNavigate();
    const { country, country_id } = useParams();
    
    const [loading, setLoading] = useState(true);
    const [gc_provinces, set_gc_provinces] = useState([]);
    const [error, setError] = useState(null);

    // Define the gesture handler
    const bind = useDrag(
        (state) => {
          const { movement: [dx, dy], last } = state;

          /*console.log('Movement:', dx, dy); // Log movement details
          console.log('Raw state:', state); // Log the full state for debugging*/

          // If the gesture ends and there's enough horizontal movement
          if (last && dx > 50) {
            console.log('Swipe detected (manual threshold)');
            navigate(-1); // Go back
          }
        },
        {
            filterTaps: true, // Ignore taps
            axis: 'x', // Restrict to horizontal swipes
            threshold: 10,
            eventOptions: { passive: true }, // Better performance on mobile
        }
    );

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    useEffect(() => {
        
        const gc_country_provinces = async () => {
            try {

                headerReq.country_id = country_id;
                api("gc_country_states", headerReq, null, "member")
                .then((result) => {

                    set_gc_provinces(result);

                }).catch((error) => {
                    

                });

            } catch (err) {
                // Handle any errors that occur during the API call
                setError(err);
            } finally {
                // Update loading state regardless of success or error
                setLoading(false);
            }
        }

        if(country != "philippines") {
            gc_country_provinces();
        }

    }, []); // Empty dependency array means this effect runs once after mount

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */



    if(country == "philippines") {
        return (<CountryPhilippines />);
    } else {

        if(loading) {
            return (
                <CommonLoader />
            );
        } else {
            
            const provinces_list = Object.entries(gc_provinces).map(([province_small_case, province]) => {
                const province_id = province.id || null;

                return (
                    <tr key={province_small_case}>
                        <td className="country-name">
                            <a href={`/state/${province_small_case}/${province_id}`}>
                                {province.state_name}
                            </a>
                        </td>
                        {/*<td className="text-right">0000</td>*/}
                    </tr>
                );
            });

            return(
                <div {...bind()}
                  style={{
                    width: '100vw',
                    height: 'fit-content',
                    touchAction: 'auto',
                    background: '#f0f0f0',
                  }}>
                    <AuthenticatedHeader />
                    <div className="wrapper">
                        <div className="section-limiter">
                            <HeaderSearch />
                        </div>

                        <p>&nbsp;</p>

                        <div className="section-limiter">
                            <center className="capitalize"><span className="state-province-name">{country}</span></center>

                            <div id="countries-wrapper">
                                <table id="country-navigator" className="table light">
                                    <thead>
                                        <tr>
                                            <th>States</th>
                                            {/*<th className="text-right">Number of Brethren</th>*/}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {provinces_list}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            );
        }
    }
}

export default Country;