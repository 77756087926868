import { React, Component, useState, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDrag } from '@use-gesture/react';

import Slider from "react-slick";
import pmccIcon from "../assets/pmcc-icon.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AuthenticatedHeader from "../Components/AuthenticatedHeader";
import HeaderSearch from "../Components/HeaderSearch";
import CommonLoader from "../CommonComponents/CommonLoader";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { api } from '../api.js';
import { config } from '../config.js';

const big_islands = [
    "Luzon",
    "Visayas",
    "Mindanao",
];

const provinces = {
    "Luzon" : [
        "Abra",
        "Albay",
        "Apayao",
        "Aurora",
        "Bataan",
        "Batanes",
        "Batangas",
        "Benguet",
        "Bulacan",
        "Cagayan",
        "Camarines Norte",
        "Camarines Sur",
        "Catanduanes",
        "Cavite",
        "Ifugao",
        "Ilocos Norte",
        "Ilocos Sur",
        "Isabela",
        "Kalinga",
        "La Union",
        "Laguna",
        "Marinduque",
        "Masbate",
        "Mountain Province",
        "Nueva Ecija",
        "Nueva Vizcaya",
        "Occidental Mindoro",
        "Oriental Mindoro",
        "Palawan",
        "Pampanga",
        "Pangasinan",
        "Quezon",
        "Quirino",
        "Rizal",
        "Romblon",
        "Sorsogon",
        "Tarlac",
        "Zambales"
    ],

    "Visayas" : [
        "Aklan",
        "Antique",
        "Biliran",
        "Bohol",
        "Capiz",
        "Cebu",
        "Eastern Samar",
        "Guimaras",
        "Iloilo",
        "Leyte",
        "Negros Occidental",
        "Negros Oriental",
        "Northern Samar",
        "Samar (Western Samar)",
        "Siquijor",
        "Southern Leyte"
    ],

    "Mindanao" : [
        "Agusan del Norte",
        "Agusan del Sur",
        "Basilan",
        "Bukidnon",
        "Camiguin",
        "Compostela Valley (Davao de Oro)",
        "Davao del Norte",
        "Davao del Sur",
        "Davao Occidental",
        "Davao Oriental",
        "Dinagat Islands",
        "Lanao del Norte",
        "Lanao del Sur",
        "Maguindanao del Norte",
        "Maguindanao del Sur",
        "Misamis Occidental",
        "Misamis Oriental",
        "Sarangani",
        "South Cotabato",
        "Sultan Kudarat",
        "Sulu",
        "Surigao del Norte",
        "Surigao del Sur",
        "Tawi-Tawi",
        "Zamboanga del Norte",
        "Zamboanga del Sur",
        "Zamboanga Sibugay"
    ]
};

const localStorage_localChurches = JSON.parse(localStorage.getItem("locale_churches"));
const localStorage_localeBrethrenCounts = JSON.parse(localStorage.getItem("locale_brethren_counts"));
const token = localStorage.getItem("auth_token");
const headerReq = { token };

const CountryPhilippines = () => {
    
    const navigate = useNavigate();
    const { country, country_id } = useParams();
    
    const [loading, setLoading] = useState(true);
    const [gc_provinces, set_gc_provinces] = useState([]);
    const [error, setError] = useState(null);
    const [selected_island, set_selected_island] = useState("Luzon");

    // Define the gesture handler
    const bind = useDrag(
        (state) => {
          const { movement: [dx, dy], last } = state;

          /*console.log('Movement:', dx, dy); // Log movement details
          console.log('Raw state:', state); // Log the full state for debugging*/

          // If the gesture ends and there's enough horizontal movement
          if (last && dx > 50) {
            console.log('Swipe detected (manual threshold)');
            navigate(-1); // Go back
          }
        },
        {
            filterTaps: true, // Ignore taps
            axis: 'x', // Restrict to horizontal swipes
            threshold: 10,
            eventOptions: { passive: true }, // Better performance on mobile
        }
    );

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    useEffect(() => {
        
        const gc_country_provinces = async () => {
            try {

                headerReq.country_id = country_id;
                api("gc_country_states", headerReq, null, "member")
                .then((result) => {

                    set_gc_provinces(result);
                    console.log(result);

                }).catch((error) => {
                    

                });

            } catch (err) {
                // Handle any errors that occur during the API call
                setError(err);
            } finally {
                // Update loading state regardless of success or error
                setLoading(false);
            }
        }

        gc_country_provinces();

    }, []); // Empty dependency array means this effect runs once after mount


    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const selectedIslandToggle = (island) => {
        set_selected_island(island);
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    if(loading) {
        return (
            <CommonLoader />
        );
    } else {
        let island_toggle = [];
        big_islands.forEach((island) => {
            console.log("selected island", selected_island);
            let island_class = "island-toggle-instance";

            if(island == selected_island) {
                island_class += " active-island";
            }

            island_toggle.push(
                <div className="col-4">
                    <div onClick={() => selectedIslandToggle(island)} className={island_class}>{island}</div>
                </div>
            );
        });

        const groupedByState = localStorage_localChurches.reduce((acc, item) => {
          // Check if the group for the country_id already exists, if not, create it
          if (!acc[item.state_id]) {
            acc[item.state_id] = [];
          }

          // Push the item into the group
          acc[item.state_id].push(item.id);
          return acc;
        }, {});

        let provinces_list = [];
        provinces[selected_island].forEach((province) => {

            const province_small_case = province.toLowerCase() || null;
            const province_id = gc_provinces[province_small_case]?.id || null;

            let state_brethren_count = 0;
            
            if(province_id && groupedByState[province_id]) {
                for(let i=0; i < groupedByState[province_id].length; i++) {
                    let brethrenCount = localStorage_localeBrethrenCounts.filter(item => item.current_locale === groupedByState[province_id][i]);
                    
                    for(let bc=0; bc < brethrenCount.length; bc++) {
                        state_brethren_count += brethrenCount[bc].count;
                    }
                }
            }

            provinces_list.push(
                <tr>
                    <td className="country-name">
                        <a href={`/state/${province_small_case}/${province_id}`}>
                            {province}
                        </a>
                    </td>
                    <td className="text-right">{state_brethren_count.toLocaleString()}</td>
                </tr>
            );
        });

        return (
            <div {...bind()}
                  style={{
                    width: '100vw',
                    height: 'fit-content',
                    touchAction: 'auto',
                    background: '#f0f0f0',
                  }}>
                <AuthenticatedHeader />
                <div className="wrapper">
                    <div className="section-limiter">
                        <HeaderSearch />
                    </div>

                    <p>&nbsp;</p>

                    <div className="section-limiter">
                        <div className="row">
                            {island_toggle}
                        </div>

                        <div id="countries-wrapper">
                            <table id="country-navigator" className="table light">
                                <thead>
                                    <tr>
                                        <th>Country</th>
                                        <th className="text-right">Number of Brethren</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {provinces_list}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default CountryPhilippines;