import { React, Component, useState, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
 import { ToastContainer, toast } from 'react-toastify';

import { api } from '../api.js';
import { config } from '../config.js';

import AuthenticatedHeader from "../Components/AuthenticatedHeader";
import HeaderSearch from "../Components/HeaderSearch";
import CommonLoader from "../CommonComponents/CommonLoader";

import pmccIcon from "../assets/pmcc-icon.png";
import fillerPhoto from "../assets/filler.png";

import 'bootstrap/dist/css/bootstrap.min.css';

const group_categories = [
    "Bishops",
    "Council Members",
    "Presbyters",
    "Area Coordinators",
    "Luzon Area Coordinators",
    "Zion",
    "Bethel",
    "Mizpah",
    "Achaia",
    "Smyrna",
    "Bethlehem",
    "Ebenezer",
    "Achaia",
    "Smyrna",
    "Bethlehem",
    "Ebenezer"
];

const auth_customId = localStorage.getItem("auth_customId");
const localStorage_localChurches = JSON.parse(localStorage.getItem("locale_churches"));
const token = localStorage.getItem("auth_token");
const headerReq = { token };

const Contact = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [contacts, setContacts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [deleteContact, setDeleteContact] = useState(0);
    const [savedContacts, setSavedContacts] = useState([]);

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

     // useEffect hook to perform side effect (API call)
    useEffect(() => {
        console.log("rendered");
        // Call the async function
        fetchContacts(auth_customId);

    }, []); // Empty dependency array means this effect runs once after mount


    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const notify = (message, type) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const fetchContacts = async (auth_id) => {
        headerReq.auth_id = auth_id;
        try {    
            api("list_contacts", headerReq, null, "member")
            .then((result) => {

                let db_contacts = result.contact_list;
                localStorage.setItem("savedContacts", result.contact_list);
                setContacts(db_contacts);

            }).catch((error) => {

                console.log(error);
                console.log("Something went wrong. " + JSON.stringify(error));

            });
        } catch (err) {
            // Handle any errors that occur during the API call
            setError(err);
        } finally {
            setLoading(false);
        }
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const removeContactTrigger = (member_id) => {
        setDeleteContact(member_id);
        setShowModal(true);
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const removeFromContacts = async () => {
        let member_id = deleteContact;
        let contact_to_delete = contacts.findIndex(contact => contact.id === member_id);
        let updatedContacts = [...contacts]; // Create a shallow copy

        headerReq.auth_id = auth_customId;
        setLoading(true);
        
        let post_data = {
            "contact_id" :  member_id
        }

        console.log("post_data", post_data);

        try {    
            api("remove_contact", headerReq, post_data, "member")
            .then((result) => {

                updatedContacts.splice(contact_to_delete, 1); // Modify the copy
                setContacts(updatedContacts); // Update state with the new array

            }).catch((error) => {

                console.log(error);
                console.log("Something went wrong. " + JSON.stringify(error));

            });
        } catch (err) {
            // Handle any errors that occur during the API call
            setError(err);
        } finally {
            notify("Contact has been removed", "success");
            setLoading(false);
            setShowModal(false);
        }
    }


    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const addToContacts = (member_id) => {
        console.log(member_id);

        let post_data = {
            "auth_id" : auth_customId,
            "contact_id" :  member_id
        }

        try {    
            api("add_contact", headerReq, post_data, "member")
            .then((result) => {

                let db_contacts = JSON.parse(result.contacts);
                localStorage.setItem("savedContacts", result.contacts);
                setSavedContacts(db_contacts);

            }).catch((error) => {

                console.log(error);
                console.log("Something went wrong. " + JSON.stringify(error));

            });
        } catch (err) {
            // Handle any errors that occur during the API call
            setError(err);
        } finally {
            notify("Contact has been added", "success");
        }
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const renderPastoraList = () => {
        let head_pastor = [];
        let pastoral_entries = [];

        if (contacts) {
            contacts.forEach((pastoral_instance, pastoral_index) => {
                let profileLink = "/contact-profile/" + pastoral_instance.id; 
                let contact_link = "tel: " + pastoral_instance.mobile;

                let photoDisplay = fillerPhoto;
                if (pastoral_instance.photo) {
                    photoDisplay = pastoral_instance.photo;
                }

                let contactActions = (<div></div>);

                let locale_instance = localStorage_localChurches.find(locale_church_instance => locale_church_instance.id === pastoral_instance.current_locale);

                contactActions = (
                    <div className="contact-infromation-wrapper locale-instance">
                        <a key={pastoral_index} href={profileLink}>
                            <div className="contact-name">{pastoral_instance.first_name} {pastoral_instance.last_name}</div>
                            <a className="typeLocale" href={`/locale/${locale_instance?.id}`}>{locale_instance?.name}</a>
                        </a>
                        <div className="contact-information">
                            <div className="contact-area">
                                <div className="contact-call-to-action">
                                    <a className="action-trigger" href={`tel:${pastoral_instance.mobile}`}>
                                        <i className="fa fa-phone" />
                                    </a>
                                </div>
                                <div className="contact-call-to-action">
                                    <a className="action-trigger" href={`sms:${pastoral_instance.mobile}`}>
                                        <i className="fa fa-comment" />
                                    </a>
                                </div>
                                <div className="contact-call-to-action">
                                    <a className="action-trigger" href={`mailto:${pastoral_instance.email}`}>
                                        <i className="fa fa-envelope" />
                                    </a>
                                </div>
                                <div className="contact-call-to-action">
                                    <div className="action-trigger" onClick={() => removeContactTrigger(pastoral_instance.id)}>
                                        <i className="fa fa-trash" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
                
                pastoral_entries.push(
                    <tr key={pastoral_index}>
                        <td>
                            <div className="locale-instance contact-designation">{pastoral_instance.type}</div>
                            <div className="locale-instance contact-image">
                                <a key={pastoral_index} href={profileLink}>
                                    <div 
                                        style={{ background: `url(${photoDisplay})` }} 
                                        className="locale-instance contact-image">
                                    </div>
                                </a>
                            </div>
                            
                            {contactActions}
                        </td>
                    </tr>
                );
            });

            return pastoral_entries; // Return the full array
        }

        return [];
    };

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */
    
    if(!loading) {
        return (
            <div>
                <AuthenticatedHeader />
                {showModal && (
                    <div className="modal-wrapper">
                        <div className="modal-backdrop fade show"></div>
                            <div className="modal d-block" tabIndex="-1" role="dialog">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Remove from favorites</h5>
                                        
                                    </div>
                                    <div className="modal-body">
                                        <p>Are you sure you want to remove this from favorites?</p>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary" onClick={() => removeFromContacts()}>
                                            Yes
                                        </button>
                                        <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
                                            No
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="wrapper">
                    
                    <div className="section-limiter">
                        <HeaderSearch />
                    </div>

                    <div className="section-limiter">
                        {/*<div className="sectionHeader">
                            <div className="row">
                                <div className="col-8"><h6 className="text-left">Contacts</h6></div>
                                <div className="col-4 text-right">
                                    <div id="add-contact">+</div>
                                </div>*
                            </div>
                        </div>*/}

                        {/*<div id="searchWrapper" className="contacts-element">
                            <div className="form-group">
                                <input className="form-control" type="text" placeholder="Search" />
                            </div>
                        </div>*/}

                        <div className="slider-container">
                          {/*<Slider {...settings}>
                            {category_toggle}
                          </Slider>*/}
                        </div>

                        <div className="sectionHeader">
                            <div className="row">
                                <div className="col-8"><h6 className="text-left">Favorites</h6></div>
                                {/*<div className="col-4"><a className="subHeaderLabel pull-right">See All</a></div>*/}
                            </div>
                        </div>

                        <div className="contacts-element" id="contactWrapper">
                            <table id="country-navigator" className="table light">
                                <tbody>
                                    {renderPastoraList()}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                
                <ToastContainer/>
            </div>
        );
    } else {
        return (
            <CommonLoader />
        );
    }
}

export default Contact;
