import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../api.js';
import { config } from '../config.js';

import searchIcon from "../assets/search-icon.png";

const HeaderSearch = () => {
  const [searchString, setSearchString] = useState("");
  const [localeChurches, setLocaleChurches] = useState([]);
  const [searchLocaleIds, setSearchLocaleIds] = useState([]);
  const [searchGroups, setSearchGroups] = useState([]);
  const navigate = useNavigate();

  /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

  function handleChange(event) {
    setSearchString(event.target.value);
  }

  /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

  function handleSubmit(event) {
    event.preventDefault();

    const localeInstance = localeChurches.filter(locale =>
      locale.localeName.toLowerCase().includes(searchString.toLowerCase())
    );

    const areaInstance = localeChurches.filter(locale =>
      locale.areaName.toLowerCase().includes(searchString.toLowerCase())
    );

    const combinedInstances = [...localeInstance, ...areaInstance];

    if (combinedInstances.length > 0) {
      setSearchLocaleIds(combinedInstances.map(locale => locale.localeId));
    }

    navigate(`/minister-list?page=1&search_string=${searchString}&locale_ids=${searchLocaleIds.join(",")}&groups=${searchGroups.join(",")}`);
  }

  /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

  return (
    <form id="minister-search" method="POST" onSubmit={handleSubmit}>
      <div id="searchWrapper">
        <div className="form-group">
          <input
            onChange={handleChange}
            className="form-control"
            type="text"
            placeholder="Search"
            value={searchString}
          />
        </div>
      </div>
    </form>
  );
};

export default HeaderSearch;