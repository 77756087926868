import { React, Component, useState, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';


import { api } from '../api.js';
import { config } from '../config.js';

import pmccIcon from "../assets/pmcc-icon.png";
import fillerPhoto from "../assets/filler.png";

import AuthenticatedHeader from "../Components/AuthenticatedHeader";
import HeaderSearch from "../Components/HeaderSearch";
import CommonLoader from "../CommonComponents/CommonLoader";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useDrag } from '@use-gesture/react';

const localStorage_localChurches = JSON.parse(localStorage.getItem("locale_churches"));
const auth_customId = localStorage.getItem("auth_customId");
const token = localStorage.getItem("auth_token");
const headerReq = { token };

const MemberByType = () => {
    const navigate = useNavigate();
    const { type } = useParams();

    const [localeChurch, setMemberByTypeChurch] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [pastoral, setPastoral] = useState([]);
    const [savedContacts, setSavedContacts] = useState([]);
    const [localeHeadMinister, setMemberByTypeHeadMinister] = useState({});
    
    const [pageLoad, setPageLoad] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(1);

    const [scrollPosition, setScrollPosition] = useState(0);

    // Define the gesture handler
    const bind = useDrag(
      (state) => {
        const { movement: [dx, dy], last } = state;

        // If the gesture ends and there's enough horizontal movement
        if (last && dx > 50) {
          console.log('Swipe detected (manual threshold)');
          navigate(-1); // Go back
        }
      },
      {
        filterTaps: true, // Ignore taps
        axis: 'x', // Restrict to horizontal swipes
        // Add preventDefault to avoid issues with PWA swipe handling
        onStart: (state) => state.event.preventDefault(),
        onMove: (state) => state.event.preventDefault(),
      }
    );

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    // useEffect hook to perform side effect (API call)
    useEffect(() => {

        // Call the async function
        fetchContacts(auth_customId);
        fetchMinisters(1, "on mount");

    }, []); // Empty dependency array means this effect runs once after mount

    // useEffect hook to perform side effect (API call)
    useEffect(() => {

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);

    }, [pageLoad, hasMore, page, pastoral]); // Empty dependency array means this effect runs once after mount

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const debounce = (func, delay) => {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => func(...args), delay);
        };
    };

    const handleScroll = debounce(() => {
        const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
        console.log("scrollTop: "+scrollTop+" scrollHeight: "+scrollHeight+" clientHeight:"+clientHeight);
        if (clientHeight + scrollTop >= scrollHeight - 5 && !pageLoad && hasMore) {
            fetchMinisters(page + 1, "scrolling");
        }
    }, 200);

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const notify = (message, type) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const fetchMinisters = async (page, from) => {
        console.log(from);
        
        setPageLoad(true);
        if(page === 1) {
            setLoading(true);
        }

        headerReq.type = type;
        headerReq.page = page;

        try {   
            api("membersByType", headerReq, null, "member")
            .then((result) => {

                if(page == 1) {
                    setPastoral(result.data);
                } else {
                    console.log("appending of data goes here");
                    console.log(result.data);
                    setPastoral((prevPastoral) => {
                        const newPastoral = result.data.filter(
                            (newItem) => !prevPastoral.some((item) => item.id === newItem.id)
                        );
                        return [...prevPastoral, ...newPastoral];
                    });
                    setPage(page);
                }
                
                if(page == result.last_page) {
                    setHasMore(false);
                } else {
                    setHasMore(result.data.length > 0);
                }

            }).catch((error) => {

                console.log(error);
                console.log("Something went wrong. " + JSON.stringify(error));

            });


        } catch (err) {
        // Handle any errors that occur during the API call
            setError(err);
        } finally {

            if(page == 1) {
                setLoading(false);
            }

            setPageLoad(false);

        }
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const fetchContacts = async (auth_id) => {
        headerReq.auth_id = auth_id;
        try {    
            api("get_contacts", headerReq, null, "member")
            .then((result) => {

                let db_contacts = JSON.parse(result.contacts);
                localStorage.setItem("savedContacts", result.contacts);
                setSavedContacts(db_contacts);

            }).catch((error) => {

                console.log(error);
                console.log("Something went wrong. " + JSON.stringify(error));

            });
        } catch (err) {
            // Handle any errors that occur during the API call
            setError(err);
        } finally {
            setLoading(false);
        }
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const addToContacts = (member_id) => {
        console.log(member_id);

        let post_data = {
            "auth_id" : auth_customId,
            "contact_id" :  member_id
        }

        try {    
            api("add_contact", headerReq, post_data, "member")
            .then((result) => {

                let db_contacts = JSON.parse(result.contacts);
                localStorage.setItem("savedContacts", result.contacts);
                setSavedContacts(db_contacts);

            }).catch((error) => {

                console.log(error);
                console.log("Something went wrong. " + JSON.stringify(error));

            });
        } catch (err) {
            // Handle any errors that occur during the API call
            setError(err);
        } finally {
            notify("Contact has been added", "success");
        }
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const renderPastoraList = () => {
        let head_pastor = [];
        let pastoral_entries = [];

        if (pastoral) {
            pastoral.forEach((pastoral_instance, pastoral_index) => {
                let profileLink = "/contact-profile/" + pastoral_instance.id; 
                let contact_link = "tel: " + pastoral_instance.mobile;

                let photoDisplay = fillerPhoto;
                if (pastoral_instance.photo) {
                    photoDisplay = pastoral_instance.photo;
                }

                let addContact = (<div></div>);
                let contactActions = (<div></div>);

                if (!savedContacts.includes(pastoral_instance.id)) {
                    addContact = (
                        <div className="contact-call-to-action">
                            <div 
                                className="action-trigger" 
                                onClick={() => addToContacts(pastoral_instance.id)} 
                                key={pastoral_index} 
                                href={profileLink}>
                                <i className="fa fa-plus" />
                            </div>
                        </div>
                    );
                }

                let locale_instance = localStorage_localChurches.find(locale_church_instance => locale_church_instance.id === pastoral_instance.current_locale);

                if(type != "deceased") {
                    contactActions = (
                        <div className="contact-infromation-wrapper locale-instance">
                            <a key={pastoral_index} href={profileLink}>
                                <div className="contact-name">{pastoral_instance.first_name} {pastoral_instance.last_name}</div>
                                <a className="typeLocale" href={`/locale/${locale_instance?.id}`}>{locale_instance?.name}</a>
                            </a>
                            <div className="contact-information">
                                <div className="contact-area">
                                    <div className="contact-call-to-action">
                                        <a className="action-trigger" href={`tel:${pastoral_instance.mobile}`}>
                                            <i className="fa fa-phone" />
                                        </a>
                                    </div>
                                    <div className="contact-call-to-action">
                                        <a className="action-trigger" href={`sms:${pastoral_instance.mobile}`}>
                                            <i className="fa fa-comment" />
                                        </a>
                                    </div>
                                    <div className="contact-call-to-action">
                                        <a className="action-trigger" href={`mailto:${pastoral_instance.email}`}>
                                            <i className="fa fa-envelope" />
                                        </a>
                                    </div>
                                    {addContact}
                                </div>
                            </div>
                        </div>
                    );
                } else {
                    const birthDate = moment(pastoral_instance?.birth_date).format('MMMM DD, YYYY'); // January 04, 1895 format
                    contactActions = (
                        <div className="contact-infromation-wrapper locale-instance">
                            <a key={pastoral_index} href={profileLink}>
                                <div className="contact-name">{pastoral_instance.first_name} {pastoral_instance.last_name}</div>
                            </a>
                            <div className="contact-information">
                                <div className="contact-area">
                                    <a href={`/locale/${locale_instance?.id}`}>{locale_instance?.name}</a>
                                    <p>
                                        <strong>Birthdate: </strong>{birthDate}
                                    </p>
                                </div>
                            </div>
                        </div>
                    );
                }
                
                pastoral_entries.push(
                    <tr key={pastoral_index}>
                        <td>
                            <div className="locale-instance contact-designation">{pastoral_instance.type}</div>
                            <div className="locale-instance contact-image">
                                <a key={pastoral_index} href={profileLink}>
                                    <img className="minister-profile-image"
                                      src={photoDisplay}
                                      srcSet={`${photoDisplay} 100w`}
                                      sizes="(max-width: 75px) 75px"
                                      alt="description"
                                      loading="lazy"
                                    />
                                </a>
                            </div>
                            
                            {contactActions}
                        </td>
                    </tr>
                );
            });

            return pastoral_entries; // Return the full array
        }

        return [];
    };

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    if(!loading) {

        let loadingDisplay = (<div></div>);
        if(hasMore) {
            loadingDisplay = (
                <div id="loadingWrapper">
                    <center>Loading....</center>
                </div>
            );
        }

        return (
            <div {...bind()}
                  style={{
                    width: '100vw',
                    height: 'fit-content',
                    touchAction: 'auto',
                    background: '#f0f0f0',
                  }}>

                <AuthenticatedHeader />
                <div className="wrapper">
                    <div className="section-limiter">
                        <HeaderSearch />
                    </div>

                    <div className="section-limiter">
                        <center id="location-name-wrapper" className="capitalize"><span className="state-province-name">{type}</span></center>

                        <div id="countries-wrapper">
                            <table id="country-navigator" className="table light">
                                <thead>
                                    <tr>
                                        <th><span className="capitalize">{type}</span> List</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderPastoraList()}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {loadingDisplay}
                    <ToastContainer/>
                </div>
            </div>
        )
    } else {
        return (
            <CommonLoader />
        );
    }
}

export default MemberByType;