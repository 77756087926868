import { React, Component, useState, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDrag } from '@use-gesture/react';

import { api } from '../api.js';
import { config } from '../config.js';

import pmccIcon from "../assets/pmcc-icon.png";
import fillerPhoto from "../assets/filler.png";

import AuthenticatedHeader from "../Components/AuthenticatedHeader";
import HeaderSearch from "../Components/HeaderSearch";
import CommonLoader from "../CommonComponents/CommonLoader";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const localStorage_localChurches = JSON.parse(localStorage.getItem("locale_churches"));
const localStorage_localeBrethrenCounts = JSON.parse(localStorage.getItem("locale_brethren_counts"));
const token = localStorage.getItem("auth_token");
const headerReq = { token };

const DistrictChurches = () => {

    const navigate = useNavigate();
    const { district_id, district_name } = useParams();
    
    const [localeChurches, setLocaleChurches] = useState([]);
    const [districtChurches, setDistrictChurches] = useState([]);
    const [error, setError] = useState(null);
    
    // Define the gesture handler
    const bind = useDrag(
        (state) => {
          const { movement: [dx, dy], last } = state;

          /*console.log('Movement:', dx, dy); // Log movement details
          console.log('Raw state:', state); // Log the full state for debugging*/

          // If the gesture ends and there's enough horizontal movement
          if (last && dx > 50) {
            console.log('Swipe detected (manual threshold)');
            navigate(-1); // Go back
          }
        },
        {
            filterTaps: true, // Ignore taps
            axis: 'x', // Restrict to horizontal swipes
            threshold: 10,
            eventOptions: { passive: true }, // Better performance on mobile
        }
    );

    useEffect(() => {

        console.log("district_id: ", district_id);
        console.log("localStorage_localChurches: ", localStorage_localChurches);
        let district_churches = localStorage_localChurches.filter(item => item.area_id == district_id);
        setDistrictChurches(district_churches);

    }, []); // Empty dependency array means this effect runs once after mount


    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    let districtLocaleChurchesList = [];
    districtChurches.forEach((districtChurchInstance) => {

        let brethrenCount = localStorage_localeBrethrenCounts.filter(item => item.current_locale === districtChurchInstance.id);
        let brethrenCountInstance = 0;

        if(brethrenCount[0]) {
            brethrenCountInstance = brethrenCount[0].count.toLocaleString();
        }
        
        districtLocaleChurchesList.push(
            <tr>
                <td className="locale-name"><a href={`/locale/${districtChurchInstance.id}`}>{districtChurchInstance.name}</a></td>
                <td className="text-right">{brethrenCountInstance}</td>
            </tr>
        );
    });
    
    return (
        <div {...bind()}
              style={{
                width: '100vw',
                height: 'fit-content',
                touchAction: 'auto',
                background: '#f0f0f0',
              }}>
            <AuthenticatedHeader />
            <div className="wrapper">
                <div className="section-limiter">
                    <HeaderSearch />
                </div>

                <p>&nbsp;</p>

                <div className="section-limiter">
                    <center className="capitalize"><span className="state-province-name">{district_name}</span></center>

                    <div id="countries-wrapper">
                        <table id="country-navigator" className="table light">
                            <thead>
                                <tr>
                                    <th>Locale Churches</th>
                                    <th className="text-right">No. of Brethren</th>
                                </tr>
                            </thead>
                            <tbody>
                                {districtLocaleChurchesList}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default DistrictChurches;