import { config } from './config.js';
import {Component} from 'react';

import { reveal as Menu } from 'react-burger-menu';
import { BrowserRouter as Router, Route, Link, Routes, Navigate, useHistory, Redirect } from 'react-router-dom';
import routes from './Routes/Routes';
import PrivateRoute from './Routes/PrivateRoute';

import Login from './Pages/login';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import './App.css';
import 'font-awesome/css/font-awesome.min.css';

import pmcclogo from "./assets/pmcc-icon.png";
import homeIco from "./assets/bottom-nav/home.png";
import contactsIco from "./assets/bottom-nav/contacts.png";
import chatIco from "./assets/bottom-nav/chat.png";
import profileIco from "./assets/bottom-nav/profile.png";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenSidebar           : true,
      account_widget_class    : "closed-widget",
      widget_toggle_class     : "fa fa-caret-down",
    };
  }

  /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

  logout = () => {
    console.log('logout');
    localStorage.clear();
    window.location.href = "/";
  }


  /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

  toggle_account_widget = () => {
    console.log('toggle');
    if(this.state.account_widget_class == "closed-widget") {
      this.setState({
        account_widget_class    : "open-widget",
        widget_toggle_class     : "fa fa-caret-up",
      });
    } else {
      this.setState({
        account_widget_class    : "closed-widget",
        widget_toggle_class     : "fa fa-caret-down",
      });
    }
  }

  /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

  render() {

    let auth_name = localStorage.getItem("auth_name");
    const handleSidebar = () => {
      this.setState({ isOpenSidebar: !this.state.isOpenSidebar });
    };

    const isAuthenticated = localStorage.getItem("authenticated");

    let class_name = "App";
    let wrapperClassName = "unauthenticated";
    let bottom_nav = null;

    if (isAuthenticated) {
      wrapperClassName = "authenticated";
      bottom_nav = (
        <div id="fixed-footer-navigation">
          <div id="fixed-footer-navigation-wrapper">
            <div className="row">
              <div className="col-3 offset-3">
                <a href="/dashboard">
                  <div className="nav-instane-wrapper">
                    <div className="nav-instance-icon"><img src={homeIco} /></div>
                    <div className="nav-instance-label">Home</div>
                  </div>                  
                </a>
              </div>
              <div className="col-3">
                <a href="/contacts">
                  <div className="nav-instane-wrapper">
                    <div className="nav-instance-icon"><img src={contactsIco} /></div>
                    <div className="nav-instance-label">Favorites</div>
                  </div>                  
                </a>
              </div>
              {/*<div className="col-3">
                <div className="nav-instane-wrapper">
                  <div className="nav-instance-icon"><img src={chatIco} /></div>
                  <div className="nav-instance-label">Chat</div>
                </div>                  
              </div>*/}
              {/*<div className="col-4">
                <a href="/contact-profile">
                  <div className="nav-instane-wrapper">
                    <div className="nav-instance-icon"><img src={profileIco} /></div>
                    <div className="nav-instance-label">Profile</div>
                  </div>                  
                </a>
              </div>*/}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className={wrapperClassName} id="mainWrapper">
          <div className={class_name} id="outer-container">
            <div id="page-wrap">
              <div id="content-wrapper" className={wrapperClassName}>
                <Router>
                  <Routes>
                    <Route
                      path="/"
                      exact={true}
                      element={
                        (isAuthenticated) ? (<Navigate to="/dashboard" />)
                        :
                        <Login isAuthenticated={isAuthenticated} />
                      }
                    />
                  </Routes>

                  {routes.map((route, index) => (
                    <PrivateRoute
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      element={route.element}
                      privateRoute={route.private} // Pass the privateRoute flag
                      isAuthenticated={isAuthenticated}
                    />
                  ))}
                </Router>
              </div>
            </div>
          </div>
        </div>
        <footer>
          {bottom_nav}
        </footer>
      </div>
    );
  }
}

export default App;