import { React, Component, useState, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
 import { ToastContainer, toast } from 'react-toastify';

import { api } from '../api.js';
import { config } from '../config.js';

import pmccIcon from "../assets/pmcc-icon.png";
import fillerPhoto from "../assets/filler.png";

import AuthenticatedHeader from "../Components/AuthenticatedHeader";
import HeaderSearch from "../Components/HeaderSearch";
import CommonLoader from "../CommonComponents/CommonLoader";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useDrag } from '@use-gesture/react';

const auth_customId = localStorage.getItem("auth_customId");
const token = localStorage.getItem("auth_token");
const headerReq = { token };

const Locale = () => {
    const navigate = useNavigate();
    const { locale_id } = useParams();

    const [localeChurch, setLocaleChurch] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [pastoral, setPastoral] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [savedContacts, setSavedContacts] = useState([]);
    const [localeStats, setLocaleStats] = useState([]);

    const [localeHeadMinister, setLocaleHeadMinister] = useState({});

    // Define the gesture handler
    const bind = useDrag(
        (state) => {
          const { movement: [dx, dy], last } = state;

          /*console.log('Movement:', dx, dy); // Log movement details
          console.log('Raw state:', state); // Log the full state for debugging*/

          // If the gesture ends and there's enough horizontal movement
          if (last && dx > 50) {
            console.log('Swipe detected (manual threshold)');
            navigate(-1); // Go back
          }
        },
        {
            filterTaps: true, // Ignore taps
            axis: 'x', // Restrict to horizontal swipes
            threshold: 10,
            eventOptions: { passive: true }, // Better performance on mobile
        }
    );

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    // useEffect hook to perform side effect (API call)
    useEffect(() => {

        // Call the async function
        fetchContacts(auth_customId);
        fetchLocaleChurch();

    }, []); // Empty dependency array means this effect runs once after mount


    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const notify = (message, type) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const fetchMinisters = async (pageNumber, search_locale_ids) => {
        setLoading(true);

        headerReq.page = pageNumber;
        headerReq.locale_id = locale_id;

        try {   
            api("allInMinistry", headerReq, null, "member")
            .then((result) => {

                setPastoral(result.members);
                setLocaleStats(result.stats);
                setCurrentPage(result.current_page);
                setTotalItems(result.total);
                setItemsPerPage(result.per_page);

            }).catch((error) => {

                console.log(error);
                console.log("Something went wrong. " + JSON.stringify(error));

            });

        } catch (err) {
        // Handle any errors that occur during the API call
            setError(err);
        } finally {
            setLoading(false);
        }
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const fetchContacts = async (auth_id) => {
        headerReq.auth_id = auth_id;
        try {    
            api("get_contacts", headerReq, null, "member")
            .then((result) => {

                let db_contacts = JSON.parse(result.contacts);
                localStorage.setItem("savedContacts", result.contacts);
                setSavedContacts(db_contacts);

            }).catch((error) => {

                console.log(error);
                console.log("Something went wrong. " + JSON.stringify(error));

            });
        } catch (err) {
            // Handle any errors that occur during the API call
            setError(err);
        } finally {
            setLoading(false);
        }
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const fetchLocaleChurch = async () => {
        
        headerReq.locale_id = locale_id;
        try {    
            api("gc_locale_instance", headerReq, null, "member")
            .then((result) => {

                setLocaleChurch(result);

            }).catch((error) => {

                console.log(error);
                console.log("Something went wrong. " + JSON.stringify(error));

            });
        } catch (err) {
            // Handle any errors that occur during the API call
            setError(err);
        } finally {
            fetchMinisters(1, locale_id);
        }
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const addToContacts = (member_id) => {
        console.log(member_id);

        let post_data = {
            "auth_id" : auth_customId,
            "contact_id" :  member_id
        }

        try {    
            api("add_contact", headerReq, post_data, "member")
            .then((result) => {

                let db_contacts = JSON.parse(result.contacts);
                localStorage.setItem("savedContacts", result.contacts);
                setSavedContacts(db_contacts);

            }).catch((error) => {

                console.log(error);
                console.log("Something went wrong. " + JSON.stringify(error));

            });
        } catch (err) {
            // Handle any errors that occur during the API call
            setError(err);
        } finally {
            notify("Contact has been added", "success");
        }
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const renderPastoraList = () => {
        let head_pastor = [];
        let pastoral_entries = [];

        if (pastoral) {
            pastoral.forEach((pastoral_instance, pastoral_index) => {
                let profileLink = "/contact-profile/" + pastoral_instance.id; 
                let contact_link = "tel: " + pastoral_instance.mobile;

                let photoDisplay = fillerPhoto;
                if (pastoral_instance.photo) {
                    photoDisplay = pastoral_instance.photo;
                }

                let addContact = (<div></div>);

                if (!savedContacts.includes(pastoral_instance.id)) {
                    addContact = (
                        <div className="contact-call-to-action">
                            <div 
                                className="action-trigger" 
                                onClick={() => addToContacts(pastoral_instance.id)} 
                                key={pastoral_index} 
                                href={profileLink}>
                                <i className="fa fa-plus" />
                            </div>
                        </div>
                    );
                }

                if (
                    (pastoral_instance.type.toLowerCase().includes("head") && !pastoral_instance.type.toLowerCase().includes("assistant")) ||
                    pastoral_instance.type.toLowerCase().includes("bishop") ||
                    pastoral_instance.type.toLowerCase().includes("coordinator") ||
                    pastoral_instance.type.toLowerCase().includes("presbyter") ||
                    pastoral_instance.type.toLowerCase().includes("council")
                ) {
                    head_pastor.push(
                        <tr key={`head-${pastoral_index}`}>
                            <td>
                                <div className="locale-instance contact-designation">{pastoral_instance.type}</div>
                                <div className="locale-instance contact-image">
                                    <a key={pastoral_index} href={profileLink}>
                                        <div 
                                            style={{ background: `url(${photoDisplay})` }} 
                                            className="locale-instance contact-image">
                                        </div>
                                    </a>
                                </div>
                                <div className="contact-infromation-wrapper locale-instance">
                                    <a key={pastoral_index} href={profileLink}>
                                        <div className="contact-name">{pastoral_instance.first_name} {pastoral_instance.last_name}</div>
                                    </a>
                                    <div className="contact-information">
                                        <div className="contact-area">
                                            <div className="contact-call-to-action">
                                                <a className="action-trigger" href={`tel:${pastoral_instance.mobile}`}>
                                                    <i className="fa fa-phone" />
                                                </a>
                                            </div>
                                            <div className="contact-call-to-action">
                                                <a className="action-trigger" href={`sms:${pastoral_instance.mobile}`}>
                                                    <i className="fa fa-comment" />
                                                </a>
                                            </div>
                                            <div className="contact-call-to-action">
                                                <a className="action-trigger" href={`mailto:${pastoral_instance.email}`}>
                                                    <i className="fa fa-envelope" />
                                                </a>
                                            </div>
                                            {addContact}
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    );
                } else {
                    pastoral_entries.push(
                        <tr key={pastoral_index}>
                            <td>
                                <div className="locale-instance contact-designation">{pastoral_instance.type}</div>
                                <div className="locale-instance contact-image">
                                    <a key={pastoral_index} href={profileLink}>
                                        <div 
                                            style={{ background: `url(${photoDisplay})` }} 
                                            className="locale-instance contact-image">
                                        </div>
                                    </a>
                                </div>
                                <div className="contact-infromation-wrapper locale-instance">
                                    <a key={pastoral_index} href={profileLink}>
                                        <div className="contact-name">{pastoral_instance.first_name} {pastoral_instance.last_name}</div>
                                    </a>
                                    <div className="contact-information">
                                        <div className="contact-area">
                                            <div className="contact-call-to-action">
                                                <a className="action-trigger" href={`tel:${pastoral_instance.mobile}`}>
                                                    <i className="fa fa-phone" />
                                                </a>
                                            </div>
                                            <div className="contact-call-to-action">
                                                <a className="action-trigger" href={`sms:${pastoral_instance.mobile}`}>
                                                    <i className="fa fa-comment" />
                                                </a>
                                            </div>
                                            <div className="contact-call-to-action">
                                                <a className="action-trigger" href={`mailto:${pastoral_instance.email}`}>
                                                    <i className="fa fa-envelope" />
                                                </a>
                                            </div>
                                            {addContact}
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    );
                }
            });

            if (head_pastor) {
                pastoral_entries.unshift(head_pastor); // Prepend head_pastor
            }
            return pastoral_entries; // Return the full array
        }

        return [];
    };

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const renderLocaleStats = () => {
        let minister_types = [
            "minister",
            "bishop",
            "coordinator",
            "council",
            "presbyter"
        ];

        let sacrifice_types = [
            "sacrifice",
            "staff",
            "vfcm",
        ];

        let minister_stats = 0;
        let minister_male_stats = 0;
        let minister_female_stats = 0;

        let bs_stats = 0;
        let bs_male_stats = 0;
        let bs_female_stats = 0;

        let sacrifice_stats = 0;
        let sacrifice_male_stats = 0;
        let sacrifice_female_stats = 0;

        localeStats.forEach((stat) => {
            if (minister_types.some(type => stat.type.toLowerCase().includes(type))) {
                minister_stats += stat.count;

                if(stat.gender == "Male") {
                    minister_male_stats += stat.count;
                }
                
                if(stat.gender == "Female") {
                    minister_female_stats += stat.count;
                }
            }

            if (stat.type.toLowerCase().includes("bible") || stat.type.toLowerCase().includes("probationary")) {
                bs_stats += stat.count;

                if(stat.gender == "Male") {
                    bs_male_stats += stat.count;
                }
                
                if(stat.gender == "Female") {
                    bs_female_stats += stat.count;
                }
            }

            if (sacrifice_types.some(type => stat.type.toLowerCase().includes(type))) {
                console.log("sacrifice stat: ", stat);
                sacrifice_stats += stat.count;

                if(stat.gender.toLowerCase() == "male") {
                    sacrifice_male_stats += stat.count;
                }
                
                if(stat.gender.toLowerCase() == "female") {
                    sacrifice_female_stats += stat.count;
                }
            }
        });

        return (
            <div className="row">
                <div className="col-4">
                    <div className="main-stat">
                        <div className="main-stat-label">Ministers:</div>
                        <div className="main-stat-value">{minister_stats}</div>
                    </div>

                    <div className="secondary-stat">
                        <div className="male-stat-breakdown">
                            <div className="secondary-stat-label">Male</div>
                            <div className="secondary-stat-value">{minister_male_stats}</div>
                        </div>
                        <div className="female-stat-breakdown">
                            <div className="secondary-stat-label">Female</div>
                            <div className="secondary-stat-value">{minister_female_stats}</div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="main-stat">
                        <div className="main-stat-label">Bible Students:</div>
                        <div className="main-stat-value">{bs_stats}</div>
                    </div>

                    <div className="secondary-stat">
                        <div className="male-stat-breakdown">
                            <div className="secondary-stat-label">Male</div>
                            <div className="secondary-stat-value">{bs_male_stats}</div>
                        </div>
                        <div className="female-stat-breakdown">
                            <div className="secondary-stat-label">Female</div>
                            <div className="secondary-stat-value">{bs_female_stats}</div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="main-stat">
                        <div className="main-stat-label">Sacrifice:</div>
                        <div className="main-stat-value">{sacrifice_stats}</div>
                    </div>

                    <div className="secondary-stat">
                        <div className="male-stat-breakdown">
                            <div className="secondary-stat-label">Male</div>
                            <div className="secondary-stat-value">{sacrifice_male_stats}</div>
                        </div>
                        <div className="female-stat-breakdown">
                            <div className="secondary-stat-label">Female</div>
                            <div className="secondary-stat-value">{sacrifice_female_stats}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    if(!loading) {
        return (
            <div {...bind()}
                  style={{
                    width: '100vw',
                    height: 'fit-content',
                    touchAction: 'auto',
                    background: '#f0f0f0',
                  }}>
                <AuthenticatedHeader />
                <div className="wrapper">
                    <div className="section-limiter">
                        <HeaderSearch />
                    </div>

                    <div className="section-limiter">
                        <center id="location-name-wrapper" className="capitalize"><span className="state-province-name">{localeChurch.name}</span></center>

                        <div id="locale-stats-wrapper">
                            {renderLocaleStats()}
                        </div>

                        <div id="countries-wrapper">
                            <table id="country-navigator" className="table light">
                                <thead>
                                    <tr>
                                        <th>Locale Staff</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderPastoraList()}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <ToastContainer/>
                </div>
            </div>
        )
    } else {
        return (
            <CommonLoader />
        );
    }
}

export default Locale;