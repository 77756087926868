export let endpoints = (request = {}) => {
    let request_headers = null;
    let request_headers_json = null;
    let user_id = null;
    let client_params = null;

    if(request != null) {
        
        if(request.token) {
            request_headers_json = { 'Authorization' : request.token, 'Content-Type' : 'application/json' };
        } else if(request.bearer_token) {
            request_headers_json = {
                Authorization: "Bearer " + request.bearer_token,
                "Content-Type": "application/json",
            };
        } else {
            request.token = null;
        }
        
        if(request.user_id) {
            user_id = request.user_id;
        }

        if(request.params) {
            client_params = request.params;
        }

    } else {
        request = {};
    }


    return {
        /* ################################ AUTHENTICATION ENDPOINTS ################################ */
        
        authentication_login: {
            url: "/api/v1/authenticate-admin",
            method: "POST",
            headers: { "Content-Type": "application/json" },
            isFileUpload: false,
        },

        mpd_authentication_login: {
            url: "/api/v2/auth/login",
            method: "POST",
            headers: { "Content-Type": "application/json" },
            isFileUpload: false,
        },

        /* ################################ MINISTER ENDPOINTS ################################ */

        minister_instance: {
            url: "/api/members/"+request.id,
            method: "GET",
            headers: request_headers_json,
            isFileUpload: false,
        },

        ministers_list: {
            url: "/api/members?page=" + request.page+"&search_string="+request.search_string+"&locale_ids="+request.locale_ids+"&groups="+request.groups,
            method: "GET",
            headers: request_headers_json,
            isFileUpload: false,
        },

        allInMinistry: {
            url: "/api/members/allInMinistry/"+request.locale_id,
            method: "GET",
            headers: request_headers_json,
            isFileUpload: false,
        },

        dashboardStats: {
            url: "/api/members/dashboardStats",
            method: "GET",
            headers: request_headers_json,
            isFileUpload: false,
        },

        membersPerLocale: {
            url: "/api/members/membersPerLocale",
            method: "GET",
            headers: request_headers_json,
            isFileUpload: false,
        },
        
        membersByType: {
            url: "/api/members/membersByType/"+request.type+"?page="+request.page,
            method: "GET",
            headers: request_headers_json,
            isFileUpload: false,
        },

        /* ################################ GLOBAL CONFIG ENDPOINTS ################################ */

        list_contacts: {
            url: "/api/list_contacts/"+request.auth_id,
            method: "GET",
            headers: request_headers_json,
            isFileUpload: false,
        },

        get_contacts: {
            url: "/api/contacts/"+request.auth_id,
            method: "GET",
            headers: request_headers_json,
            isFileUpload: false,
        },

        add_contact: {
            url: "/api/contacts",
            method: "POST",
            headers: request_headers_json,
            isFileUpload: false,
        },

        remove_contact: {
            url: "/api/remove_contacts/"+request.auth_id,
            method: "POST",
            headers: request_headers_json,
            isFileUpload: false,
        },

        update_contact: {
            url: "/api/contacts"+request.id,
            method: "POST",
            headers: request_headers_json,
            isFileUpload: false,
        },

        /* ################################ GLOBAL CONFIG ENDPOINTS ################################ */

        gc_all_countries: {
            url: "/api/gc_all_countries",
            method: "GET",
            headers: request_headers_json,
            isFileUpload: false,
        },

        gc_country_states: {
            url: "/api/gc_country_states/"+request.country_id,
            method: "GET",
            headers: request_headers_json,
            isFileUpload: false,
        },

        gc_city_locales: {
            url: "/api/gc_city_locales/"+request.state_id,
            method: "GET",
            headers: request_headers_json,
            isFileUpload: false,
        },

        gc_city_locales: {
            url: "/api/gc_city_locales/"+request.state_id,
            method: "GET",
            headers: request_headers_json,
            isFileUpload: false,
        },

        gc_locale_instance: {
            url: "/api/gc_locale_instance/"+request.locale_id,
            method: "GET",
            headers: request_headers_json,
            isFileUpload: false,
        },

        gc_all_locales: {
            url: "/api/gc_all_locales",
            method: "GET",
            headers: request_headers_json,
            isFileUpload: false,
        },

        gc_all_area: {
            url: "/api/gc_all_area",
            method: "GET",
            headers: request_headers_json,
            isFileUpload: false,
        }
    };
};
