import React from "react";
const auth_name = localStorage.getItem("auth_name");

class AuthenticatedHeader extends React.Component {
  render() {
    return (
      <header className="authenticated">
        <h6>
          <small>Welcome back!</small><br />
          {auth_name}
        </h6>
      </header>
    );
  }
}

export default AuthenticatedHeader;
