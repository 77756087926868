import { React, Component, useState, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useDrag } from '@use-gesture/react';
import { api } from '../api.js';
import { config } from '../config.js';

import Slider from "react-slick";

import pmccIcon from "../assets/pmcc-icon.png";
import fillerPhoto from "../assets/filler.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Pagination from '../Components/Pagination';
import CommonLoader from "../CommonComponents/CommonLoader";
import AuthenticatedHeader from "../Components/AuthenticatedHeader";

const localStorage_localChurches = JSON.parse(localStorage.getItem("locale_churches"));
const auth_customId = localStorage.getItem("auth_customId");
const token = localStorage.getItem("auth_token");
const headerReq = { token };
const settings = {
    slidesToShow: 3.5,
    rows: 2,
    slidesPerRow: 1,

    infinite: false,        // Disable infinite scroll
    swipeToSlide: true,     // Allow scrolling to any position
    speed: 300,             // Adjust speed for smoother transitions
};

const group_categories = [
    "Bishop",
    "Council Members",
    "Head Minister",
    "Bethel",
    "Mizpah",
    "Achaia",
    "Smyrna",
    "Bethlehem",
    "Ebenezer",
    "Achaia",
    "Smyrna",
    "Bethlehem",
    "Ebenezer"
];

const MinisterList = () => {
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);

    const [searchString, setSearchString] = useState(searchParams.get("search_string") || "");
    const [searchLocaleIds, setSearchLocaleIds] = useState(
        searchParams.get("locale_ids") ? searchParams.get("locale_ids").split(",") : []
    );
    const [searchGroups, setSearchGroups] = useState(
        searchParams.get("groups") ? searchParams.get("groups").split(",") : []
    );

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [initial_ministers, setInitialMinisters] = useState([]);
    const [ministers, setMinisters] = useState([]);

    const [pageLoad, setPageLoad] = useState(true);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(1);
    const [savedContacts, setSavedContacts] = useState([]);

    const [scrollPosition, setScrollPosition] = useState(0);

    // Define the gesture handler
    const bind = useDrag(
        (state) => {
          const { movement: [dx, dy], last } = state;

          /*console.log('Movement:', dx, dy); // Log movement details
          console.log('Raw state:', state); // Log the full state for debugging*/

          // If the gesture ends and there's enough horizontal movement
          if (last && dx > 50) {
            console.log('Swipe detected (manual threshold)');
            navigate(-1); // Go back
          }
        },
        {
            filterTaps: true, // Ignore taps
            axis: 'x', // Restrict to horizontal swipes
            threshold: 10,
            eventOptions: { passive: true }, // Better performance on mobile
        }
    );

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    useEffect(() => {

        fetchContacts(auth_customId);
        if(searchString) {
            console.log("searchString: ", searchString);
            fetchSearchData(1);
        } else {
            fetchMinisters(1);
        }   

    }, []); // Empty dependency array means this effect runs once after mount

    // useEffect hook to perform side effect (API call)
    useEffect(() => {

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);

    }, [pageLoad, hasMore, page, ministers]); // Empty dependency array means this effect runs once after mount


    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const debounce = (func, delay) => {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => func(...args), delay);
        };
    };

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const handleScroll = debounce(() => {
        const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
        console.log("scrollTop: "+scrollTop+" scrollHeight: "+scrollHeight+" clientHeight:"+clientHeight);
        if (clientHeight + scrollTop >= scrollHeight - 5 && !pageLoad && hasMore) {
            fetchMinisters(page + 1);
        }
    }, 200);


    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const notify = (message, type) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const addGroup = (group_params) => {
        // Find the index of the group in the searchGroups array
        const index = searchGroups.findIndex(g => g === group_params);

        if (index === -1) {
            // If the group doesn't exist in the array, add it
            searchGroups.push(group_params);
        } else {
            // If the group already exists, remove it
            searchGroups.splice(index, 1);
        }

        // Update the state with the new searchGroups array
        console.log(searchGroups);
        this.setState({ searchGroups });


        if(searchString) {
            console.log("searchString: ", searchString);
            this.fetchSearchData();
        } else {
            this.fetchMinisters(1);
        }
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */
    
    const fetchSearchData = () => {
        let searchLocaleIds = [];

        const localeInstance = localStorage_localChurches.filter(function (locale) {
            return locale.name.toLowerCase().includes(searchString.toLowerCase());
        });

        console.log("searchString: ", searchString);
        console.log("localeInstance: ", localeInstance);

        if(localeInstance.length > 0) {
            localeInstance.forEach((locale) => {
                searchLocaleIds.push(locale.id);
            });
        }

        console.log("searchLocaleIds", searchLocaleIds);

        if(searchString == "") {
            fetchMinisters(1, []);
        } else {
            fetchMinisters(1, searchLocaleIds);
        }
    }


    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const fetchContacts = async (auth_id) => {
        headerReq.auth_id = auth_id;
        try {    
            api("get_contacts", headerReq, null, "member")
            .then((result) => {

                let db_contacts = JSON.parse(result.contacts);
                localStorage.setItem("savedContacts", result.contacts);
                setSavedContacts(db_contacts);

            }).catch((error) => {

                console.log(error);
                console.log("Something went wrong. " + JSON.stringify(error));

            });
        } catch (err) {
            // Handle any errors that occur during the API call
            setError(err);
        } finally {
            setLoading(false);
        }
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const addToContacts = (member_id) => {
        console.log(member_id);

        let post_data = {
            "auth_id" : auth_customId,
            "contact_id" :  member_id
        }

        try {    
            api("add_contact", headerReq, post_data, "member")
            .then((result) => {

                let db_contacts = JSON.parse(result.contacts);
                localStorage.setItem("savedContacts", result.contacts);
                setSavedContacts(db_contacts);

            }).catch((error) => {

                console.log(error);
                console.log("Something went wrong. " + JSON.stringify(error));

            });
        } catch (err) {
            // Handle any errors that occur during the API call
            setError(err);
        } finally {
            notify("Contact has been added", "success");
        }
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const fetchMinisters = async (page, search_locale_ids) => {
        
        setPageLoad(true);
        if(page === 1) {
            setLoading(true);
        }

        // headerReq.type = type;
        headerReq.page = page;
        headerReq.search_string = searchString;
        headerReq.groups = searchGroups;
        
        if(search_locale_ids) {
            setSearchLocaleIds(search_locale_ids);
            headerReq.locale_ids = search_locale_ids;
        } else {
            headerReq.locale_ids = searchLocaleIds;
        }
        
        if(searchString == "") {
            headerReq.locale_ids = [];
            searchLocaleIds = [];
        }

        try {  
            api("ministers_list", headerReq, null, "member")
            .then((result) => {

                console.log(page);

                if(page == 1) {
                    setMinisters(result.data);
                } else {
                    console.log("appending of data goes here");
                    console.log(result.data);
                    
                    setMinisters((prevMinisters) => {
                        const newMinisters = result.data.filter(
                            (newItem) => !prevMinisters.some((item) => item.id === newItem.id)
                        );
                        return [...prevMinisters, ...newMinisters];
                    });

                    setPage(page);
                }
                
                if(page == result.last_page) {
                    setHasMore(false);
                } else {
                    setHasMore(result.data.length > 0);
                }

            }).catch((error) => {

                console.log(error);
                console.log("Something went wrong. " + JSON.stringify(error));

            });
        } catch (err) {
        // Handle any errors that occur during the API call
            setError(err);
        } finally {

            if(page == 1) {
                setLoading(false);
            }

            setPageLoad(false);

        }
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const handleChange = (event) => {
        setSearchString(event.target.value);
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const handleSubmit = (submit) => {
        submit.preventDefault();
        
        // RESETTING PAGINATION HERE
        setHasMore(true);
        setPage(1);

        fetchSearchData();
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    const renderMinisterList = () => {
        let head_pastor = [];
        let minister_entries = [];

        if (ministers) {
            ministers.forEach((minister_instance, minister_index) => {
                let profileLink = "/contact-profile/" + minister_instance.id; 
                let contact_link = "tel: " + minister_instance.mobile;

                let photoDisplay = fillerPhoto;
                if (minister_instance.photo) {
                    photoDisplay = minister_instance.photo;
                }

                let addContact = (<div></div>);
                let contactActions = (<div></div>);

                if (!savedContacts.includes(minister_instance.id)) {
                    addContact = (
                        <div className="contact-call-to-action">
                            <div 
                                className="action-trigger" 
                                onClick={() => addToContacts(minister_instance.id)} 
                                key={minister_index} 
                                href={profileLink}>
                                <i className="fa fa-plus" />
                            </div>
                        </div>
                    );
                }

                let locale_instance = localStorage_localChurches.find(locale_church_instance => locale_church_instance.id === minister_instance.current_locale);

                contactActions = (
                    <div className="contact-infromation-wrapper locale-instance">
                        <a key={minister_index} href={profileLink}>
                            <div className="contact-name">{minister_instance.first_name} {minister_instance.last_name}</div>
                            <a className="typeLocale" href={`/locale/${locale_instance?.id}`}>{locale_instance?.name}</a>
                        </a>
                        <div className="contact-information">
                            <div className="contact-area">
                                <div className="contact-call-to-action">
                                    <a className="action-trigger" href={`tel:${minister_instance.mobile}`}>
                                        <i className="fa fa-phone" />
                                    </a>
                                </div>
                                <div className="contact-call-to-action">
                                    <a className="action-trigger" href={`sms:${minister_instance.mobile}`}>
                                        <i className="fa fa-comment" />
                                    </a>
                                </div>
                                <div className="contact-call-to-action">
                                    <a className="action-trigger" href={`mailto:${minister_instance.email}`}>
                                        <i className="fa fa-envelope" />
                                    </a>
                                </div>
                                {addContact}
                            </div>
                        </div>
                    </div>
                );
                
                minister_entries.push(
                    <tr key={minister_index}>
                        <td>
                            <div className="locale-instance contact-designation">{minister_instance.type}</div>
                            <div className="locale-instance contact-image">
                                <a key={minister_index} href={profileLink}>
                                    <div 
                                        style={{ background: `url(${photoDisplay})` }} 
                                        className="locale-instance contact-image">
                                    </div>
                                </a>
                            </div>
                            
                            {contactActions}
                        </td>
                    </tr>
                );
            });

            return minister_entries; // Return the full array
        }

        return [];
    };

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    let slider_entries = [];

    group_categories.forEach((group, group_index) => {
        let groupClass = "groupButtonInstance";
        if(searchGroups.includes(group)) {
            groupClass += " active-group";
        }

        slider_entries.push(
            <div key={group_index} onClick={() => this.addGroup(group)} className={groupClass}>
                <div className="buttonWrapper">
                    <img src={pmccIcon} />
                </div>
                <div className="group-label">{group}</div>
            </div>
        );
    });

    let displayLimit = 20;
    let inc = 0;

    if(!loading) {

        let loadingDisplay = (<div></div>);
        if(hasMore) {
            loadingDisplay = (
                <div id="loadingWrapper">
                    <center>Loading....</center>
                </div>
            );
        }

        return (
            <div>
                <AuthenticatedHeader />
                <div className="wrapper">
                    <div className="section-limiter">
                        <form id="minister-search" method="POST" onSubmit={handleSubmit}>
                            <div id="searchWrapper">
                                <div className="form-group">
                                    <input onChange={(event) => handleChange(event)} className="form-control" type="text" placeholder="Search" value={searchString} />
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="section-limiter">
                        <div className="sectionHeader">
                            <div className="row">
                                <div className="col-8"><h6 className="text-left">Search Results</h6></div>
                                <div className="col-4"><a className="subHeaderLabel pull-right"></a></div>
                            </div>
                        </div>
                    </div>

                    {/*<div id="dashboard-slider-container" className="slider-container">
                      <Slider {...settings}>
                        {slider_entries}
                      </Slider>
                    </div>*/}

                     <div className="section-limiter">
                        <table id="country-navigator" className="table light">
                            <tbody>
                                {renderMinisterList()}
                            </tbody>
                        </table>
                    </div>

                    {loadingDisplay}
                    <ToastContainer/>
                </div>
            </div>
        )
    } else {
      return (
        <CommonLoader />
      );
    }
}

export default MinisterList;