import {Component} from 'react';

import React from "react";
import Slider from "react-slick";

import pmccIcon from "../assets/pmcc-icon.png";

import ArtF from "../assets/ArtF.webp";
import DodoyF from "../assets/DodoyF.webp";
import OsieQ from "../assets/OsieQ.webp";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AuthenticatedHeader from "../Components/AuthenticatedHeader";
import HeaderSearch from "../Components/HeaderSearch";
import CommonLoader from "../CommonComponents/CommonLoader";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { api } from '../api.js';
import { config } from '../config.js';

const localStorage_localChurches = JSON.parse(localStorage.getItem("locale_churches"));
const localStorage_localeBrethrenCounts = JSON.parse(localStorage.getItem("locale_brethren_counts"));
const token = localStorage.getItem("auth_token");
const headerReq = { token };

const continents = [
    "Africa",
    "Asia",
    "Europe",
    "North America",
    "South America",
    "Oceania",
];

const countries = {
    "Africa" : [
        "Algeria",
        "Angola",
        "Benin",
        "Botswana",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cameroon",
        "Central African Republic",
        "Chad",
        "Comoros",
        "Democratic Republic of the Congo",
        "Republic of the Congo",
        "Djibouti",
        "Egypt",
        "Equatorial Guinea",
        "Eritrea",
        "Eswatini",
        "Ethiopia",
        "Gabon",
        "Gambia",
        "Ghana",
        "Guinea",
        "Guinea-Bissau",
        "Ivory Coast",
        "Kenya",
        "Lesotho",
        "Liberia",
        "Libya",
        "Madagascar",
        "Malawi",
        "Mali",
        "Mauritania",
        "Mauritius",
        "Morocco",
        "Mozambique",
        "Namibia",
        "Niger",
        "Nigeria",
        "Rwanda",
        "Sao Tome and Principe",
        "Senegal",
        "Seychelles",
        "Sierra Leone",
        "Somalia",
        "South Africa",
        "South Sudan",
        "Sudan",
        "Tanzania",
        "Togo",
        "Tunisia",
        "Uganda",
        "Zambia",
        "Zimbabwe"
    ],
    "Asia" : [
        "Afghanistan",
        "Armenia",
        "Azerbaijan",
        "Bahrain",
        "Bangladesh",
        "Bhutan",
        "Brunei",
        "Cambodia",
        "China",
        "Cyprus",
        "Georgia",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Israel",
        "Japan",
        "Jordan",
        "Kazakhstan",
        "Kuwait",
        "Kyrgyzstan",
        "Laos",
        "Lebanon",
        "Malaysia",
        "Maldives",
        "Mongolia",
        "Myanmar",
        "Nepal",
        "North Korea",
        "Oman",
        "Pakistan",
        "Palestine",
        "Philippines",
        "Qatar",
        "Saudi Arabia",
        "Singapore",
        "South Korea",
        "Sri Lanka",
        "Syria",
        "Taiwan",
        "Tajikistan",
        "Thailand",
        "Timor-Leste",
        "Turkey",
        "Turkmenistan",
        "United Arab Emirates",
        "Uzbekistan",
        "Vietnam",
        "Yemen"
    ],
    "Europe" : [
        "Albania",
        "Andorra",
        "Armenia",
        "Austria",
        "Azerbaijan",
        "Belarus",
        "Belgium",
        "Bosnia and Herzegovina",
        "Bulgaria",
        "Croatia",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Estonia",
        "Finland",
        "France",
        "Georgia",
        "Germany",
        "Greece",
        "Hungary",
        "Iceland",
        "Ireland",
        "Italy",
        "Kazakhstan",
        "Kosovo",
        "Latvia",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Malta",
        "Moldova",
        "Monaco",
        "Montenegro",
        "Netherlands",
        "North Macedonia",
        "Norway",
        "Poland",
        "Portugal",
        "Romania",
        "Russia",
        "San Marino",
        "Serbia",
        "Slovakia",
        "Slovenia",
        "Spain",
        "Sweden",
        "Switzerland",
        "Turkey",
        "Ukraine",
        "United Kingdom",
        "Vatican City"
    ],
    "North America" : [
        "Antigua and Barbuda",
        "Bahamas",
        "Barbados",
        "Belize",
        "Canada",
        "Costa Rica",
        "Cuba",
        "Dominica",
        "Dominican Republic",
        "El Salvador",
        "Grenada",
        "Guatemala",
        "Haiti",
        "Honduras",
        "Jamaica",
        "Mexico",
        "Nicaragua",
        "Panama",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Vincent and the Grenadines",
        "Trinidad and Tobago",
        "United States"
    ],
    "South America" : [
        "Argentina",
        "Bolivia",
        "Brazil",
        "Chile",
        "Colombia",
        "Ecuador",
        "Guyana",
        "Paraguay",
        "Peru",
        "Suriname",
        "Uruguay",
        "Venezuela"
    ],
    "Oceania" : [
        "Australia",
        "Fiji",
        "Kiribati",
        "Marshall Islands",
        "Micronesia",
        "Nauru",
        "New Zealand",
        "Palau",
        "Papua New Guinea",
        "Samoa",
        "Solomon Islands",
        "Tonga",
        "Tuvalu",
        "Vanuatu"
    ]
};

const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    rows: 1,
    slidesPerRow: 1,
    infinite: false,        // Disable infinite scroll
    swipeToSlide: true,     // Allow scrolling to any position
    speed: 300,             // Adjust speed for smoother transitions
    variableWidth: true
};

class Countries extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            selected_continent: "Africa",
            gc_countries: {}
        };
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    async componentDidMount(){        
        await this.gc_all_countries();
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    selectedContinentToggle = (continent) => {
        console.log(continent)
        this.setState({selected_continent :  continent });
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    gc_all_countries = async () => {
        
        api("gc_all_countries", headerReq, null, "member")
        .then((result) => {

            this.setState({gc_countries : result});

        }).catch((error) => {
            

        });
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    render() {
        let that = this;
        let gc_countries = this.state.gc_countries;
        let continent_toggle = [];
        continents.forEach((continent) => {

            console.log("selected continent", this.state.selected_continent);
            let continent_class = "continent-toggle-instance";

            if(continent == this.state.selected_continent) {
                continent_class += " active-continent";
            }

            continent_toggle.push(
                <div onClick={() => this.selectedContinentToggle(continent)} className={continent_class}>
                    <div className="group-label">{continent}</div>
                </div>
            );
        });

        const groupedByCountry = localStorage_localChurches.reduce((acc, item) => {
          // Check if the group for the country_id already exists, if not, create it
          if (!acc[item.country_id]) {
            acc[item.country_id] = [];
          }

          // Push the item into the group
          acc[item.country_id].push(item.id);
          return acc;
        }, {});

        let countries_list = [];
        countries[this.state.selected_continent].forEach((country) => {

            let country_lower_case = country.toLowerCase();
            let flag_url = "/world_flags/" + country_lower_case + ".png";
            
            let country_id = 0;
            if(gc_countries[country_lower_case]) {
                country_id = gc_countries[country_lower_case].id;
            }

            country.toLowerCase();
            const countryExists = localStorage_localChurches.some(item => item.country_id === country_id);

            if(countryExists) {

                let country_brethren_count = 0;

                for(let i=0; i < groupedByCountry[country_id].length; i++) {
                    let brethrenCount = localStorage_localeBrethrenCounts.filter(item => item.current_locale === groupedByCountry[country_id][i]);
                    
                    console.log("groupd by country id: ", groupedByCountry[country_id]);
                    console.log("country brethren count: ", brethrenCount);
                    
                    for(let bc=0; bc < brethrenCount.length; bc++) {
                        country_brethren_count += brethrenCount[bc].count;
                    }
                }

                countries_list.push(
                    <tr>
                        <td className="country-name"><a href={`/country/${country_lower_case}/${country_id}`}><img className="country-circle-flag" src={flag_url} />{country}</a></td>
                        <td className="text-right">{country_brethren_count.toLocaleString()}</td>
                    </tr>
                );
            }
        });

        if(!this.state.isLoading) {
            return (
                <div>
                    <AuthenticatedHeader />
                    <div className="wrapper">
                        <div className="section-limiter">
                            <HeaderSearch />
                        </div>

                        <p>&nbsp;</p>

                        {/*<Tabs>
                            <TabList>
                              <Tab>Asia</Tab>
                              <Tab>Africa</Tab>
                              <Tab>Europe</Tab>
                              <Tab>Middle East</Tab>
                              <Tab>North America</Tab>
                              <Tab>South America</Tab>
                            </TabList>

                            <TabPanel>
                              <h2>Any content 1</h2>
                            </TabPanel>
                            <TabPanel>
                              <h2>Any content 2</h2>
                            </TabPanel>
                          </Tabs>*/}

                        <div id="continent_toggle" className="slider-container">
                          <Slider {...settings}>
                            {continent_toggle}
                          </Slider>
                        </div>

                        <div className="section-limiter">
                            <div id="countries-wrapper">
                                <table id="country-navigator" className="table light">
                                    <thead>
                                        <tr>
                                            <th>Country</th>
                                            <th className="text-right"># of Brethren</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {countries_list}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            )
        } else {
          return (
            <CommonLoader />
          );
        }
    }
}

export default Countries;